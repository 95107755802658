import {AnimatePresence, motion} from "framer-motion"
import {noop} from "lodash"
import {FC, Suspense} from "react"
import {useLocation} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Loading} from "@frontend/components"
import {MobileOnly} from "@frontend/components/responsive"
import {IconButton} from "@frontend/components/ui/button"
import {TooltipWrapper} from "@frontend/components/ui/tooltip"
import {iconsColor} from "@frontend/design"
import close from "@frontend/design/icons/left-panel-close.svg"
import library from "@frontend/design/icons/library.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {useSession} from "@frontend/session"
import {trpc} from "@frontend/utils/trpc"
import {Case} from "@ri2/db/client"
import {Argument, css, cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

import {CaseSummary} from "./case-summary"
import {ConsentButton} from "./consent-button"
import {ContactButton} from "./contact-button"
import {CreateNewCaseButton} from "./create-new-case-button"
import {EmptyCasesList} from "./empty-cases-list"
import {FeedbackButton} from "./feedback-button"

const BUTTONS_MIN_WIDTH = 44

interface Props {
  expanded: boolean
  onToggle: () => void
  onNavigate: () => void
  case?: Case
  css?: Argument
}

export const Sidebar: FC<Props> = ({
  expanded,
  onToggle,
  onNavigate,
  case: caseProp,
  css: cssProp = "",
}) => {
  const t = useTranslation()

  const currentCaseId = caseProp?.id

  const afterShowModal = expanded ? onToggle : (): void => {}

  const location = useLocation()
  const rootPath = makePath()
  const isRootPage = location.pathname === rootPath
  const title = t("appContainer.sidebar.cases.title")

  return (
    <AnimatePresence initial={false}>
      <div
        aria-label={title}
        role="region"
        className={cx(
          css(
            {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "white",
              paddingTop: {
                base: 0,
                largeDesktop: 16,
              },
              paddingBottom: 16,
              overflowX: {
                base: "hidden",
                desktop: "unset",
              },
              transition: "min-width 0.2s linear, padding-left 0.3s linear",
              zIndex: "sidebar",
            },

            expanded
              ? {
                  alignItems: "stretch",
                  borderRightWidth: 1,
                  borderRightStyle: "solid",
                  borderRightColor: "lineGrey",
                  overflowX: "visible",
                }
              : {
                  alignItems: "center",
                  paddingLeft: {
                    base: 0,
                  },
                },
          ),
          cssProp,
        )}
      >
        <div
          className={vstack({
            gap: 24,
            justifyContent: "flex-start",
            width: "100%",
          })}
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 8,
              width: "100%",
              textStyle: "button",
              height: {
                base: 56,
                largeDesktop: 36,
              },
              paddingRight: 16,
              paddingLeft: {
                base: 10,
                largeDesktop: 16,
              },
            })}
          >
            <TooltipWrapper
              content={title}
              positioning="bottom"
              variant="dark"
              disabled={expanded}
            >
              <IconButton
                variant="unstyled"
                icon={
                  <ReactSVG
                    src={library}
                    className={css({
                      width: 24,
                      "& svg": {
                        width: 24,
                        height: 24,
                      },
                    })}
                  />
                }
                ariaLabel={title}
                onClick={expanded ? noop : onToggle}
                css={css.raw(
                  {
                    display: {
                      base: "none",
                      desktop: "none",
                      largeDesktop: "flex",
                    },
                    minWidth: BUTTONS_MIN_WIDTH,
                    height: "36px",
                  },
                  !expanded && {
                    _hover: {
                      backgroundColor: "background.secondary",
                    },
                  },
                )}
              />
            </TooltipWrapper>
            <motion.span
              animate={expanded ? "open" : "collapsed"}
              className={css({
                display: expanded
                  ? "block"
                  : {base: "none", desktop: "none", largeDesktop: "block"},
              })}
              variants={{
                open: {opacity: 1, transition: {duration: 0.1, delay: 0.3}},
                collapsed: {opacity: 0, transition: {duration: 0.1}},
              }}
            >
              {title}
            </motion.span>
            {expanded && (
              <div
                className={css({
                  display: {base: "block", largeDesktop: "contents"},
                  position: "absolute",
                  right: -48,
                })}
              >
                <IconButton
                  variant="unstyled"
                  icon={
                    <>
                      <ReactSVG
                        src={close}
                        className={css({
                          display: {
                            base: "none",
                            largeDesktop: "block",
                          },
                        })}
                      />
                      <ReactSVG
                        src={close}
                        className={cx(
                          iconsColor({color: "white"}),
                          css({
                            largeDesktop: {
                              display: "none",
                            },
                          }),
                        )}
                      />
                    </>
                  }
                  ariaLabel={t("appContainer.sidebar.close")}
                  css={css.raw({
                    marginLeft: "auto",
                    minWidth: BUTTONS_MIN_WIDTH,
                    _hover: {
                      largeDesktop: {
                        backgroundColor: "background.secondary",
                      },
                    },
                  })}
                  onClick={expanded ? onToggle : (): void => {}}
                />
              </div>
            )}
          </div>
        </div>

        <motion.div
          animate={expanded ? "open" : "collapsed"}
          variants={{
            open: {opacity: 1, transition: {duration: 0.1, delay: 0.3}},
            collapsed: {opacity: 0, transition: {duration: 0.1}},
          }}
          className={css({
            flex: 1,
            paddingY: {
              base: 0,
              largeDesktop: 8,
            },
          })}
          style={{
            overflowY: "auto",
            paddingBottom: -48,
            marginRight: -20,
            paddingRight: 20,
            minWidth: "min-content",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {expanded && (
            <Suspense
              fallback={
                <Loading
                  text={t("appContainer.sidebar.loading")}
                  className={css({height: "100%"})}
                />
              }
            >
              <Cases onNavigate={onNavigate} currentCaseId={currentCaseId} />
            </Suspense>
          )}
          <div
            className={css({
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              height: 48,
              background:
                "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
            })}
          ></div>
        </motion.div>
        <div
          className={cx(
            css({
              alignItems: "center",
              flexDirection: "column",
              gap: 8,
              width: "100%",
              paddingTop: 16,
              paddingRight: 16,
              paddingLeft: 16,
              display: expanded
                ? "flex"
                : {base: "none", desktop: "none", largeDesktop: "flex"},
            }),
          )}
        >
          {!isRootPage && (
            <MobileOnly>
              <CreateNewCaseButton
                isSidebarOpen={expanded}
                afterClick={afterShowModal}
              />
              <FeedbackButton
                isSidebarOpen={expanded}
                afterClick={afterShowModal}
              />
            </MobileOnly>
          )}
          <ConsentButton isSidebarOpen={expanded} afterClick={afterShowModal} />
          <ContactButton isSidebarOpen={expanded} afterClick={afterShowModal} />
        </div>
      </div>
    </AnimatePresence>
  )
}

interface CasesProps {
  onNavigate: () => void
  currentCaseId?: string
}

const Cases: FC<CasesProps> = ({onNavigate, currentCaseId}) => {
  const {userRcId} = useSession()
  const [cases] = trpc.caseSummaries.useSuspenseQuery({userRcId})

  if (!cases.length) return <EmptyCasesList />

  return (
    <ul
      className={css({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 12,
      })}
    >
      {cases.map((summary) => (
        <li key={summary.id}>
          <CaseSummary
            caseSummary={summary}
            onNavigate={onNavigate}
            isCurrentCase={currentCaseId === summary.id}
          />
        </li>
      ))}
    </ul>
  )
}
