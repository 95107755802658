import {FC} from "react"
import {Trans} from "react-i18next"
import {ReactSVG} from "react-svg"

import riIcon from "@frontend/design/icons/ri-icon.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

export const NoConfirmedCauses: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingY: 16,
        gap: 4,
        alignItems: "flex-start",
      })}
    >
      <ReactSVG
        src={riIcon}
        className={css({
          marginBottom: 8,
          "& svg": {
            width: 42,
            height: 36,
          },
        })}
      />
      <h2
        className={css({
          fontSize: 32,
          lineHeight: "36px",
          fontWeight: 300,
          color: "text.primary",
          letterSpacing: "-0.02em",
        })}
      >
        <Trans i18nKey="cases.case.keepChatting">
          <span className={css({color: "text.brand.accent"})} />
        </Trans>
      </h2>
      <p
        className={css({
          textStyle: "bodyLight",
          color: "text.tertiary",
        })}
      >
        {t("cases.case.keepChattingReason")}
      </p>
    </div>
  )
}
