import {AnimatePresence, motion} from "framer-motion"
import {FC, PropsWithChildren, Suspense, useState} from "react"
import {useLocation} from "react-router-dom"
import shadowRoot from "react-shadow"
import {ReactSVG} from "react-svg"

import {FeedbackButton, Loading} from "@frontend/components"
import {NewCaseButton} from "@frontend/components/new-case-button"
import {
  DesktopAndLargeDesktop,
  DesktopOnly,
  MobileOnly,
} from "@frontend/components/responsive"
import {IconButton} from "@frontend/components/ui/button"
import {iconsColor} from "@frontend/design"
import closeIcon from "@frontend/design/icons/close.svg"
import logo from "@frontend/design/icons/logo.svg"
import stylesheet from "@frontend/routes/globals.css?inline"
import {makePath} from "@frontend/routing"
import {useSession} from "@frontend/session"
import {APP_ROOT_ID, SHADOW_HOST_ID} from "@frontend/utils/getAppRoot"
import {trpc} from "@frontend/utils/trpc"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import type {UiCase} from "@ri2/app/server/routers/getCasePageData"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CaseHeader} from "./case-header"
import {AppContainerContext, Breadcrumb} from "./context"
import {Sidebar} from "./sidebar"
import {ToggleSidebarButton} from "./toggle-sidebar-button"
import {useTranslation} from "../i18n"

const HEADER_HEIGHT_MOBILE = 66
const HEADER_HEIGHT_DESKTOP = 76

const BORDER_RADIUS = 12

const SIDEBAR_COLLAPSED_WIDTH = 68
const SIDEBAR_EXPANDED_WIDTH_DESKTOP = 248

interface Props {
  onExit: () => void
}

export const AppContainer: FC<PropsWithChildren<Props>> = ({
  onExit,
  children,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])

  const [caseModel, setCaseModel] = useState<UiCase | undefined>(undefined)

  const {userRcId} = useSession()
  // query the case summaries so that they are hopefully populated by
  // the time the sidebar is opened
  trpc.caseSummaries.useQuery({userRcId}, {suspense: false})

  const {
    state: isSidebarExpanded,
    toggle: onToggleSidebar,
    setFalse: onHideSidebar,
  } = useBooleanState(false)

  const onNavigate = (): void => {
    onHideSidebar()
  }

  const location = useLocation()
  const rootPath = makePath()
  const isRootPage = location.pathname === rootPath

  const t = useTranslation()

  // We need to replace the `:where(:root, :host)` selector with `:where(:host)`
  // because CSS variables declared on both :root and :host do not work correctly
  // in the shadow DOM, see https://github.com/chakra-ui/panda/issues/1300 for more details
  const styleSheetForShadowRoot = stylesheet
    .replace(/:where\(:root,:host\)/g, ":where(:host)")
    .replace(/:where\(:root, :host\)/g, ":where(:host)")

  return (
    <shadowRoot.div id={SHADOW_HOST_ID}>
      <>
        <style>{styleSheetForShadowRoot}</style>
        <div
          onClick={onExit}
          className={css({
            position: "fixed",
            inset: 0,
            backgroundColor: {
              base: "#273D5F",
              desktop: "rgba(0, 0, 0, 0.3)",
            },
            backdropFilter: {
              base: "unset",
              desktop: "blur(40px)",
            },
            zIndex: "appBackdrop",
          })}
        />
        <div
          data-panda-theme="green"
          data-mode="dark"
          id={APP_ROOT_ID}
          className={css({
            position: "fixed",
            zIndex: "appModal",
            alignItems: "center",
            justifyContent: "center",
            inset: 0,
            borderTopRadius: 12,
            fontFamily: "ABC Monument Grotesk, sans-serif",
            desktop: {
              inset: 20,
              borderRadius: BORDER_RADIUS,
              overflow: "hidden",
              display: "flex",
              justifyContent: "flex-start",
              flexGrow: 1,
            },
          })}
        >
          <Sidebar
            expanded={isSidebarExpanded}
            onToggle={onToggleSidebar}
            onNavigate={onNavigate}
            case={caseModel}
            css={cx(
              css({
                position: {
                  base: "absolute",
                  largeDesktop: "relative",
                },
                top: 0,
                left: 0,
                bottom: 0,
                zIndex: "sidebar",

                display: "flex",
                height: "100%",
                largeDesktop: {
                  borderBottomLeftRadius: BORDER_RADIUS,
                },
              }),
              isSidebarExpanded
                ? css({
                    minWidth: {
                      base: "85%",
                      largeDesktop: SIDEBAR_EXPANDED_WIDTH_DESKTOP,
                    },
                  })
                : css({
                    minWidth: {
                      base: 0,
                      largeDesktop: SIDEBAR_COLLAPSED_WIDTH,
                    },
                  }),

              isSidebarExpanded
                ? css({
                    width: {
                      base: "85%",
                      largeDesktop: SIDEBAR_EXPANDED_WIDTH_DESKTOP,
                    },
                  })
                : css({
                    width: {
                      base: 0,
                      largeDesktop: SIDEBAR_COLLAPSED_WIDTH,
                    },
                  }),
            )}
          />
          <div
            className={cx(
              css({
                display: "flex",
                flexDirection: "column",
                textStyle: "body",
                height: "100%",
                flexGrow: 1,
                borderTopRadius: BORDER_RADIUS,
                backgroundColor: "background.primary",
                desktop: {
                  borderTopLeftRadius: 0,
                  borderRightRadius: BORDER_RADIUS,
                  borderLeftRadius: 0,
                },
              }),
            )}
          >
            <header
              className={css(
                {
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: HEADER_HEIGHT_MOBILE,
                  paddingRight: 16,
                  gap: 16,
                  backgroundColor: "white",
                  paddingTop: 16,
                  paddingBottom: 8,
                  boxSizing: "border-box",

                  desktopOnly: {
                    maxWidth: "calc(100vw - 64px)",
                  },

                  desktop: {
                    height: HEADER_HEIGHT_DESKTOP,
                    paddingY: 6,
                    paddingRight: 0,
                    paddingLeft: 0,
                    gap: 20,
                    marginRight: 16,
                  },
                },
                isSidebarExpanded
                  ? {
                      desktop: {
                        marginLeft: 32,
                      },
                    }
                  : {
                      desktop: {
                        marginLeft: 8,
                      },
                    },
              )}
            >
              {!caseModel && (
                <img
                  src={logo}
                  alt={t("appContainer.header.title")}
                  className={css({
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",

                    height: {
                      base: 44,
                      desktop: 46,
                    },

                    flexGrow: 1,

                    largeDesktop: {
                      marginLeft: -17,
                    },
                  })}
                />
              )}

              <MobileOnly>
                <ToggleSidebarButton onToggleSidebar={onToggleSidebar} />
              </MobileOnly>
              <div
                className={hstack({
                  gap: 8,
                })}
              >
                <DesktopOnly>
                  <ToggleSidebarButton onToggleSidebar={onToggleSidebar} />
                </DesktopOnly>
                {caseModel && <CaseHeader caseModel={caseModel} />}
              </div>
              <div
                className={hstack({
                  gap: 0,
                  height: 44,
                  alignItems: "center",
                })}
              >
                {!isRootPage && (
                  <>
                    <DesktopAndLargeDesktop>
                      <FeedbackButton css={css.raw({flexShrink: 0})} />
                    </DesktopAndLargeDesktop>
                    <DesktopAndLargeDesktop>
                      <NewCaseButton css={css.raw({flexShrink: 0})} />
                    </DesktopAndLargeDesktop>
                  </>
                )}
                <div
                  className={css({
                    height: 36,
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    minWidth: "fit-content",
                    flex: 0,
                  })}
                >
                  <div
                    className={hstack({
                      gap: 16,
                      minWidth: "fit-content",
                    })}
                  >
                    <IconButton
                      variant="unstyled"
                      ariaLabel={t("general.close")}
                      onClick={onExit}
                      css={css.raw({
                        "& svg": {width: 24, height: 24},
                        width: 44,
                        height: 44,
                      })}
                      icon={
                        <ReactSVG
                          src={closeIcon}
                          className={iconsColor({
                            color: "black",
                          })}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
            </header>
            <AnimatePresence initial={false}>
              {isSidebarExpanded && (
                <motion.div
                  onClick={onToggleSidebar}
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: {opacity: 0.8},
                    collapsed: {opacity: 0},
                  }}
                  className={css({
                    display: {base: "block", largeDesktop: "none"},
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundColor: "black",
                    opacity: 0.8,
                    zIndex: "sidebarBackdrop",
                  })}
                />
              )}
            </AnimatePresence>

            <div
              className={css({
                flex: 1,
                position: "relative",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
                margin: {
                  base: 0,
                  desktop: "0px 8px 0px 8px",
                  largeDesktop: "0px 8px 0px 0px",
                },
              })}
            >
              <div
                className={css({
                  flex: 1,
                  overflowY: "auto",
                })}
              >
                <AppContainerContext.Provider
                  value={{
                    breadcrumbs,
                    setBreadcrumbs,
                    setCase: setCaseModel,
                    isSidebarExpanded,
                    onToggleSidebar,
                  }}
                >
                  <Suspense
                    fallback={
                      <Loading
                        className={css({width: "100%", height: "100%"})}
                      />
                    }
                  >
                    {children}
                  </Suspense>
                </AppContainerContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </>
    </shadowRoot.div>
  )
}
