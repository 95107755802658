import {FC} from "react"
import {ReactSVG} from "react-svg"

import riIcon from "@frontend/design/icons/ri-icon.svg"
import {useTranslation} from "@frontend/i18n"
import type {UiCauses} from "@ri2/app/server/routers/getCasePageData"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CausesList} from "./CausesList"
import {NoConfirmedCauses} from "./NoConfirmedCauses"
import {MORE_AND_VERY_LIKELY_CAUSES} from "./types"
import {UnconfirmedCauses} from "./unconfirmed-causes"

const EMPTY_CAUSES: UiCauses = {
  confirmed: [],
  moreAndVeryLikely: [],
  ruledOut: [],
}

interface Props {
  caseId: string
  causes?: UiCauses
  className?: string
}

export const Causes: FC<Props> = ({
  caseId,
  causes = EMPTY_CAUSES,
  className,
}) => {
  const t = useTranslation()

  return (
    <div
      role="complementary"
      aria-label={t("causes.ariaLabel")}
      className={cx(
        css({
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: {base: "unset", desktop: "relative"},
          zIndex: 0, // to avoid children appearing on top of the sidebar
          paddingX: 32,
          desktop: {
            paddingRight: 24,
            paddingLeft: 4,
          },
          largeDesktop: {
            paddingLeft: 8,
            paddingRight: 25,
          },
          paddingTop: {
            base: 24,
            desktop: 0,
          },
          maxHeight: {
            base: "101%",
            desktop: "calc(100vh - 128px)",
          },
          desktopDown: {
            backgroundColor: "background.secondary",
          },
        }),
        className,
      )}
    >
      <div
        className={css({
          marginTop: {
            desktop: 40,
          },
        })}
      >
        <h2
          className={css({
            fontWeight: 300,
            fontSize: 24,
            lineHeight: "30px",
            color: "text.primary",
            letterSpacing: "-0.01em",
            marginBottom: 24,
            display: {
              base: "none",
              desktop: "block",
            },
          })}
        >
          {t("cases.case.nextStepsTitle")}
        </h2>
        {causes.confirmed.length > 0 ? (
          <>
            <div
              className={hstack({
                gap: 8,
                alignItems: "center",
                justifyContent: "flex-start",
                paddingY: 8,
              })}
            >
              <ReactSVG
                src={riIcon}
                className={css({
                  "& svg": {
                    width: 24,
                    height: 21,
                  },
                })}
              />
              <h2
                className={css({
                  textStyle: "bodyLargeMedium",
                  color: "text.primary",
                  letterSpacing: "-0.01em",
                })}
              >
                {t("cases.case.likelyCauses")}
              </h2>
            </div>
            <CausesList
              causes={causes.confirmed}
              caseId={caseId}
              causeLikelihood={MORE_AND_VERY_LIKELY_CAUSES}
              causeVariant="confirmed"
            />
          </>
        ) : (
          <NoConfirmedCauses />
        )}
      </div>
      <UnconfirmedCauses causes={causes} caseId={caseId} />
    </div>
  )
}
