import {FC, ReactNode, useEffect} from "react"
import {useSearchParams} from "react-router-dom"

import {CaseTab} from "@frontend/routing"
import {vstack} from "@styled-system/patterns"

import {useLastConversationTab} from "../../util/useLastConversationTab"
import {ChatCausesSwitch} from "../chat-causes-switch"

export const MobileCaseLayout: FC<{
  conversation: ReactNode
  causesList: ReactNode
  causesCount: number
  showCauses: boolean
}> = ({conversation, causesList, causesCount, showCauses}) => {
  const {setValue} = useLastConversationTab()

  const [params, setParams] = useSearchParams()

  const tabParam = params.get("tab")

  const tab = (tabParam ?? "conversation") as CaseTab

  const onChangeTab = (tab: CaseTab): void => {
    if (tab === "conversation") {
      setParams({tab})
    } else {
      setParams({tab})
    }
  }

  useEffect(() => {
    if (!tabParam) {
      setParams({tab: "conversation"})
    }
    setValue(tab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setParams, tabParam, tab])

  return (
    <div
      className={vstack({
        display: {
          base: "flex",
          desktop: "none",
        },
        gridColumnStart: 1,
        gridColumnEnd: 7,
        alignItems: "stretch",
        height: "100%",
        minHeight: 0,
        gap: 0,
        flexGrow: 1,
      })}
    >
      <ChatCausesSwitch
        causesCount={causesCount}
        showCauses={showCauses}
        tab={tab}
        onChangeTab={onChangeTab}
      />
      {tab === "conversation" && <>{conversation}</>}
      {tab === "causes" && <>{causesList}</>}
    </div>
  )
}
