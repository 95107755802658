import {motion} from "framer-motion"
import {FC} from "react"

import {TabButton} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {CaseTab} from "@frontend/routing"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

interface ChatCausesSwitchProps {
  causesCount: number
  showCauses: boolean
  tab: CaseTab
  onChangeTab: (tab: CaseTab) => void
}

export const ChatCausesSwitch: FC<ChatCausesSwitchProps> = ({
  causesCount,
  showCauses,
  tab,
  onChangeTab,
}) => {
  const t = useTranslation()

  return (
    <motion.div
      initial={false}
      variants={{
        atTheBottom: {
          y: 0,
          transition: {duration: 0.1, ease: "easeOut"},
        },
        atTheTop: {
          y: -56,
          minHeight: 0,
          transition: {duration: 0.5, ease: "easeOut"},
        },
      }}
      animate={showCauses ? "atTheBottom" : "atTheTop"}
      className={vstack({
        alignSelf: "center",
        zIndex: 1,
        bottom: 0,
        backgroundColor: "white",
        width: "100%",
        display: {base: "flex", desktop: "none"},
        justifyContent: "center",
      })}
    >
      <div
        className={hstack({
          height: 56,
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingX: 32,
          position: "relative",
          gap: 24,
          _after: {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 4,
            width: "100%",
            zIndex: -1,
            backgroundColor: "border.brand.secondary",
          },
        })}
      >
        <TabButton
          className={css({
            width: "fit-content",
            paddingX: 2,
            height: "100%",
          })}
          title={t("cases.case.chat")}
          active={tab === "conversation"}
          onClick={(): void => {
            onChangeTab("conversation")
          }}
        />
        <TabButton
          className={hstack({
            alignItems: "center",
            paddingX: 2,
            height: "100%",
          })}
          title={t("cases.case.causes")}
          active={tab === "causes"}
          onClick={(): void => {
            onChangeTab("causes")
          }}
        >
          <Badge count={causesCount} selected={tab === "causes"} />
        </TabButton>
      </div>
    </motion.div>
  )
}

interface BadgeProps {
  count: number
  selected: boolean
}

export const Badge: FC<BadgeProps> = ({count, selected}) => (
  <div
    className={cx(
      css(
        {
          padding: 4,
          boxSizing: "border-box",
          height: 20,
          width: 30,
          fontSize: 12,
          borderRadius: 6,
          textStyle: "captionRegular",
        },
        selected
          ? {background: "background.subtle.brand"}
          : {background: "background.brand.primary"},
      ),
      hstack({justifyContent: "center"}),
    )}
  >
    {count}
  </div>
)
