// Keeping this code out of the main component avoid rerenders on the confirmed causes list
import {FC, useState} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {useTranslation} from "@frontend/i18n"
import type {UiCauses} from "@ri2/app/server/routers/getCasePageData"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CausesList} from "./CausesList"
import {ToggleButton} from "./ToggleButton"
import {
  CauseLikelihood,
  MORE_AND_VERY_LIKELY_CAUSES,
  RULED_OUT_CAUSES,
} from "./types"

export const UnconfirmedCauses: FC<{causes: UiCauses; caseId: string}> = ({
  causes,
  caseId,
}) => {
  const t = useTranslation()
  const [selectedLikelihood, setSelectedLikelihood] = useState<CauseLikelihood>(
    MORE_AND_VERY_LIKELY_CAUSES,
  )
  const setOtherCauses =
    (causeLikelihood: CauseLikelihood): (() => void) =>
    (): void => {
      setSelectedLikelihood(causeLikelihood)
    }

  const otherSelectedCauses = causes[selectedLikelihood]

  const colorVariant = {
    [MORE_AND_VERY_LIKELY_CAUSES]: "informational" as const,
    [RULED_OUT_CAUSES]: "warning" as const,
  }
  return (
    <div className={css({marginTop: 49})}>
      <div
        className={hstack({
          justifyContent: "space-between",
          gap: 1,
          width: "100%",
          marginBottom: 8,
          alignItems: "center",
          desktopOnly: {
            flexDirection: "column",
            gap: 4,
            alignItems: "flex-start",
          },
        })}
      >
        <h2
          className={css({
            color: "text.primary",
            textStyle: "bodyLargeMedium",
            letterSpacing: "-0.01em",
          })}
        >
          {t("cases.case.otherCauses")}
        </h2>
        <div
          className={hstack({
            padding: 4,
            gap: 6,
            borderRadius: 999,
            backgroundColor: "background.tertiary",
            width: "fit-content",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "border.primary.300",
          })}
        >
          <ToggleButton
            selected={selectedLikelihood === MORE_AND_VERY_LIKELY_CAUSES}
            title={t("cases.case.possible")}
            onSelected={setOtherCauses(MORE_AND_VERY_LIKELY_CAUSES)}
            count={causes.moreAndVeryLikely.length}
          />
          <ToggleButton
            selected={selectedLikelihood === RULED_OUT_CAUSES}
            title={t("cases.case.ruledOut")}
            onSelected={setOtherCauses(RULED_OUT_CAUSES)}
            count={causes.ruledOut.length}
          />
        </div>
      </div>
      {otherSelectedCauses.length > 0 ? (
        <CausesList
          causes={otherSelectedCauses}
          caseId={caseId}
          causeLikelihood={selectedLikelihood}
        />
      ) : (
        <InformationBanner
          content={
            selectedLikelihood === MORE_AND_VERY_LIKELY_CAUSES
              ? t(`cases.case.noOtherCausesMessage.possible`)
              : t(`cases.case.noOtherCausesMessage.ruledOut`)
          }
          variant={colorVariant[selectedLikelihood]}
        />
      )}
      <div className={css({height: {base: 64, desktop: 0}})} />
    </div>
  )
}
